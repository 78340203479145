import styled from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
export var StyledTables = styled.div.withConfig({
  componentId: "sc-r669ak-0"
})(["width:95%;font-family:inherit;line-height:inherit;letter-spacing:inherit;margin:2rem auto 0;@media (min-width:", "){width:80%;}"], breakPoints.desktop);
export var TableWrapper = styled.div.withConfig({
  componentId: "sc-r669ak-1"
})(["flex-direction:column;align-items:center;", ""], function (props) {
  switch (props.breakPoint) {
    case 'desktop':
      return "\n          display: none;\n          @media (min-width: ".concat(breakPoints.tabletL, ") {\n            display: flex;\n          }\n        ");
    case 'mobile':
      return "\n          display: flex;\n          @media (min-width: ".concat(breakPoints.tabletL, ") {\n            display: none;\n          }\n        ");
    default:
      return "\n          display: flex;\n        ";
  }
});
export var StyledTable = styled.div.withConfig({
  componentId: "sc-r669ak-2"
})(["display:flex;flex-direction:column;font-family:inherit;width:", ";border:", ";margin-bottom:2rem;"], function (_ref) {
  var tableWidth = _ref.tableWidth;
  return tableWidth ? tableWidth + '%' : '100%';
}, function (_ref2) {
  var tableBorderColor = _ref2.tableBorderColor;
  return tableBorderColor ? '1px solid ' + tableBorderColor : '';
});
export var StyledTableRow = styled.div.withConfig({
  componentId: "sc-r669ak-3"
})(["display:flex;width:100%;"]);
export var StyledTableCol = styled.div.withConfig({
  componentId: "sc-r669ak-4"
})(["width:", ";display:flex;font-weight:", ";font-style:", ";color:", ";background-color:", ";border:", ";border-bottom:", ";border-left:", ";border-right:", ";border-top:", ";justify-content:", ";text-align:", ";align-items:", ";font-size:", ";text-transform:", ";padding-left:", ";padding-top:", ";padding-right:", ";padding-bottom:", ";overflow-wrap:anywhere;}"], function (_ref3) {
  var colWidth = _ref3.colWidth;
  return colWidth ? colWidth + '%' : '100%';
}, function (_ref4) {
  var bold = _ref4.bold;
  return bold ? 'bold' : '';
}, function (_ref5) {
  var italic = _ref5.italic;
  return italic ? 'italic' : '';
}, function (_ref6) {
  var color = _ref6.color;
  return color ? color : '';
}, function (_ref7) {
  var backgroundColor = _ref7.backgroundColor;
  return backgroundColor ? backgroundColor : '';
}, function (_ref8) {
  var borderColor = _ref8.borderColor;
  return borderColor ? 'solid ' + borderColor : '';
}, function (_ref9) {
  var borderBColor = _ref9.borderBColor,
    borderSize = _ref9.borderSize;
  return borderBColor ? borderSize + 'px solid ' + borderBColor : '';
}, function (_ref10) {
  var borderLColor = _ref10.borderLColor,
    borderSize = _ref10.borderSize;
  return borderLColor ? borderSize + 'px solid ' + borderLColor : '';
}, function (_ref11) {
  var borderRColor = _ref11.borderRColor,
    borderSize = _ref11.borderSize;
  return borderRColor ? borderSize + 'px solid ' + borderRColor : '';
}, function (_ref12) {
  var borderTColor = _ref12.borderTColor,
    borderSize = _ref12.borderSize;
  return borderTColor ? borderSize + 'px solid ' + borderTColor : '';
}, function (_ref13) {
  var align = _ref13.align;
  return align ? align : '';
}, function (_ref14) {
  var align = _ref14.align;
  return align ? align : '';
}, function (_ref15) {
  var vAlign = _ref15.vAlign;
  return vAlign ? vAlign : '';
}, function (_ref16) {
  var fontSize = _ref16.fontSize;
  return fontSize ? fontSize + 'rem' : '';
}, function (_ref17) {
  var upperCase = _ref17.upperCase;
  return upperCase ? 'uppercase' : '';
}, function (_ref18) {
  var paddingLeft = _ref18.paddingLeft,
    gPadding = _ref18.gPadding;
  return paddingLeft ? "".concat(paddingLeft, "rem") : "".concat(gPadding, "rem");
}, function (_ref19) {
  var paddingTop = _ref19.paddingTop,
    gPadding = _ref19.gPadding;
  return paddingTop !== '' ? "".concat(paddingTop, "rem") : "".concat(gPadding, "rem");
}, function (_ref20) {
  var paddingRight = _ref20.paddingRight,
    gPadding = _ref20.gPadding;
  return paddingRight ? "".concat(paddingRight, "rem") : "".concat(gPadding, "rem");
}, function (_ref21) {
  var paddingBottom = _ref21.paddingBottom,
    gPadding = _ref21.gPadding;
  return paddingBottom ? "".concat(paddingBottom, "rem") : "".concat(gPadding, "rem");
});