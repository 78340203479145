import React from "react";
var __jsx = React.createElement;
import { StyledTables, TableWrapper, StyledTable, StyledTableRow, StyledTableCol } from './TableEditor.styles.js';
export default function TableEditor(_ref) {
  var tables = _ref.tables,
    anchorLink = _ref.anchorLink;
  return __jsx(React.Fragment, null, (tables === null || tables === void 0 ? void 0 : tables.length) > 0 && __jsx(StyledTables, {
    id: anchorLink
  }, tables === null || tables === void 0 ? void 0 : tables.map(function (table, index) {
    var rows = table.rows,
      globals = table.globals;
    return __jsx(TableWrapper, {
      key: index,
      breakPoint: globals.breakPoint
    }, __jsx(StyledTable, {
      tableWidth: globals === null || globals === void 0 ? void 0 : globals.tableWidth,
      tableBorderColor: globals === null || globals === void 0 ? void 0 : globals.borderColor,
      className: "nva-table-editor"
    }, rows === null || rows === void 0 ? void 0 : rows.map(function (row, index) {
      var _row$columns;
      return __jsx(StyledTableRow, {
        key: index,
        className: "nva-table-editor__row"
      }, row === null || row === void 0 ? void 0 : (_row$columns = row.columns) === null || _row$columns === void 0 ? void 0 : _row$columns.map(function (col, colIndex) {
        return __jsx(StyledTableCol, {
          key: colIndex,
          bold: col === null || col === void 0 ? void 0 : col.bold,
          italic: col === null || col === void 0 ? void 0 : col.italic,
          color: col === null || col === void 0 ? void 0 : col.color,
          backgroundColor: col === null || col === void 0 ? void 0 : col.backgroundColor,
          borderColor: col === null || col === void 0 ? void 0 : col.borderColor,
          borderSize: globals.borderSize,
          borderBColor: col === null || col === void 0 ? void 0 : col.borderBColor,
          borderLColor: col === null || col === void 0 ? void 0 : col.borderLColor,
          borderRColor: col === null || col === void 0 ? void 0 : col.borderRColor,
          borderTColor: col === null || col === void 0 ? void 0 : col.borderTColor,
          align: col === null || col === void 0 ? void 0 : col.align,
          vAlign: col === null || col === void 0 ? void 0 : col.vAlign,
          colWidth: col === null || col === void 0 ? void 0 : col.colWidth,
          fontSize: col === null || col === void 0 ? void 0 : col.fontSize,
          upperCase: col === null || col === void 0 ? void 0 : col.upperCase,
          gPadding: globals.padding,
          paddingTop: col === null || col === void 0 ? void 0 : col.paddingTop,
          paddingRight: col === null || col === void 0 ? void 0 : col.paddingRight,
          paddingBottom: col === null || col === void 0 ? void 0 : col.paddingBottom,
          paddingleft: col === null || col === void 0 ? void 0 : col.paddingleft,
          className: "nva-table-editor__col"
        }, col === null || col === void 0 ? void 0 : col.val);
      }));
    })));
  })));
}